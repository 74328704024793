/* start style card */

.card {
    margin: 30px auto 33px;
}

/* =>  img */

.div-img {
    height: 290px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    overflow: hidden;
}

/* .div-img{
    height : 290px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    overflow: hidden;
} */


img.card-img-top.img-fluid {
    padding: 25px;
    height: 17rem;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
}



/* 
img.card-img-top.img-fluid {
    
} */




img.card-img-top.img-fluid:hover {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    opacity: 0.6;
}

@media (max-width: 767px) {
    img.card-img-top.img-fluid {
        height: 18rem;
    }
}




/* img  <= */

/* => title */

.product-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.product-name a {
    color: #009688;
}

/* title <= */

/* =>  body */

.card-body {
    text-align: center;
    align-content: center;
    display: grid;
}

/*  body  <= */


.discount{
    color:red
}

.description {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

p.description.card-text {
    height: auto;
}

.card-footer {
    padding-top: 20px;
}

/* end style card */
  




