/* start main rules */
a{
    text-decoration: none !important;
}
/* end main rules */


.navbar-div{
    background-color: #212529;
}


/* brand style */
span.navbar-brand {
    font-weight: 600;
    font-family: math;
    color: #fff !important;
    padding: 7px;
    text-decoration: none;
    border-radius:5px;
    -webkit-border-radius:5px;
    -moz-border-radius:5px;
    -ms-border-radius:5px;
    -o-border-radius:5px;
    -webkit-border-radius:5px;
    transition: .2s;
    -webkit-transition: .2s;
    -moz-transition: .2s;
    -ms-transition: .2s;
    -o-transition: .2s;
}

span.navbar-brand:hover{
    color : #009688 !important
}


/* end brand style */

/* Start Style right links */
.navbar-nav a{
    color: white !important;
    font-weight: 500;
    font-style: italic;
    /* padding-left: 15px; */
    padding: 10px;
    text-decoration: none;
}

.navbar-nav a:hover{
    color:#009688 !important
}

.navbar-nav a:focus{
    color : #dc3545 !important
}


@media (min-width:991px) {
    .navbar-nav a{
        display: flex;
        align-items: center;
    }
}


@media (min-width:768px) {
    .links{
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
    }
}

/* end style right links */

/* start cart icon */

.cart-number{
    position: relative;
    top: -12px;
    right: 15px;
}

/* end cart icon */

button.navbar-toggler,
button.navbar-toggler.collapsed{
   
    background-color: white;
}

button.navbar-toggler:focus{
    border: 2px solid #009688;
}


/* start Dropdown  style */
button#dropdown-basic {
    background-color: transparent;
    outline: none;
    margin-left: 10px;
    border: 1px solid transparent;
}

.navbar-expand-md .navbar-nav .dropdown-menu {
    background-color: #212529;
    position: absolute;
    left: -115px;
    margin-top: 6px;
   
}


a.dropdown-item:hover{
    background-color: #2a343e;
}

@media (max-width : 767px) {
    .navbar-expand-md .navbar-nav .dropdown-menu{
        left: auto;
    }
}


.logout-icon{
    cursor: pointer;
    color :rgb(181, 18, 50)
}

.logout-icon:hover,
.logout-icon:focus{
    color :rgb(217, 11, 11)
}

@media (max-width : 767px) {
    .logout-icon{
        display: flex;
        justify-content:flex-start;
        align-items:flex-start;
        padding-left: 12px;
    }
}

@media (min-width : 768px) {
    .logout-icon{
        margin-left: 10px;
    }
}

/* end Dropdown style */