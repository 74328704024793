::selection{
    color:  #009688;
}

.page-container{
    display: flex;
    flex-direction: column;
    height: 100vh;
}
.router-div{
    flex: 1;
}

@media (max-width:767px) {
    .container{
        width: auto !important;
    }
}
