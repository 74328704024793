.categories{
    margin-top : 60px
}


.title{
    text-align: center;
    padding: 20px;
    color:#009688;
    font-family:fantasy;
}

.row-category-name{
    padding-top: 20px;
}

.category-name button{
    background-color: #60A3D9;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 10px;
    color: white;
    border-radius:5px;
    -webkit-border-radius:5px;
    -moz-border-radius:5px;
    -ms-border-radius:5px;
    -o-border-radius:5px;
}


.buttons{
    display: grid;
    grid-template-columns: repeat(5 , 1fr);
    justify-content: center;
}

@media (max-width : 992px) {
    .buttons{
        display: grid;
        grid-template-columns: repeat(2 , 1fr);
        justify-content: center;
    }
}

 
.buttons .filter-btn {
    margin: 3px;
    background-color: transparent;
    border: 1px solid #009688;
    color: #009688;
    outline: none;
  
} 


.buttons .filter-btn:hover,
.buttons .filter-btn:focus{
    background-color: #dc3545;
    color : white ;
    border: none;
    outline: none;
}

.search-input{
    width: 400px;
    background-color: transparent;
    color:#dc3545;
    border: none;
    padding: 5px;
    outline: none;
    border-bottom: 1px solid #009688;
}
.search-input:focus{
    outline: none !important;
    box-shadow: none;
    font-size:large;
    background-color: transparent;
    color: #dc3545;
}

@media (max-width : 767px) {
    .search-input{
        width : 200px
    }
}

/* start filter by price  */

.price-filter{
    display: flex;
    justify-content:flex-end;
    align-items: center;
}


@media (max-width:767px) {
    .price-filter{
        display: flex;
        justify-content:flex-start;
        align-items: center;
        padding-top: 20px;
    }

    .price-filter span{
        font-weight: 500 !important;
        font-size: 21px !important;
        
        
    }
}

.price-filter span{
    font-weight: 700;
    font-size: 23px;
    color:#009688;
    padding-right: 10px;
}

.price-filter .select{
    width: 132px;
    border: none;
    cursor: pointer;
}
.price-filter .select:focus{
    border: none;
    outline: none;
    box-shadow: none;
}



/* end filter by price */