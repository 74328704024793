.orders-container{
    text-align: center;
}

.orders-col{
    padding-top : 50px
}


/* arrows-icon  */
.arrows-icon{
    visibility:hidden;
 }

@media (max-width:539px) {
    .arrows-icon{
        visibility:visible;
     }
}


.orders-table{
    background-color: #212529ed !important;
    color: white;
    border-color: gray ;
}


.order-image{
    width: 40px;
    height: 40px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}


@media (max-width : 767px) {
    .orders-col{
        width : 280px !important;
    }
    .orders-table{
        width:250px !important;
        margin: auto;
        
    }


}

