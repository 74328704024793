h1{
    text-align: center;
    padding-top: 110px;
    padding-bottom:25px ;
    color: #009688;
    font-weight: 700;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}



/* box stats */

.state {
    border: 1px solid #009688;
    width: 235px;
    padding: 10px;
    margin-left: 24px;
    border-radius: 11px;
   
}

@media (max-width : 768px) {
    .state{
        width : 188px
    }
}

.state h5{
    color: #009688;
}

.checkout-btn{
    width : 165px;
}

.div-cart-img{
    text-align: center;
    
}

.empty-image{
    width : 550px;
    height: 550px;
}

@media (max-width : 767px) {
    .empty-image{
        width : 250px;
        height: 250px;
        padding-top: 60px;
    }
}


/* start style in case not user and cart empty */

.notAuthAndEmpty-col{
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.notAuthAndEmpty-img{
    margin-right: 15px;
}

.notAuthAndEmpty-btns a button:first-child{
    margin-right: 5px !important;
    background-color: #009688 !important;
}

.notAuthAndEmpty-btns a button:last-child{
    background-color: #0f5132 !important;
}

.notAuthAndEmpty-btns a button:last-child:hover{
    background-color: #0b2c1d !important;
}

/* end style in case not user and cart not empty */

/* start style card */

.card{
    margin: 30px auto 33px;
}



/* =>  img */


/*  style for old  page structure */

/* 
.div-img{
    height : 290px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    overflow: hidden;
}

img.card-img-top.img-fluid {
    padding: 25px;
} */




/* img  <= */

/* =>  body */


/*  style for old page structure */


/* .card-body {
    text-align: center;
    align-content: center;
    display: grid;
} */

/*  body  <= */

.row{
    margin-bottom: 45px;
}



/* increase & decrease icon */

.incr-decr-icon{
    padding: 10px;
}

.incr-icon{
    color:#009688 !important;
    cursor: pointer;
    margin-right: 15px;
}

.decr-icon{
    color:#dc3545 !important;
    cursor: pointer;
}



/* style for old structure  */

/* .description{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

p.description.card-text {
    height: auto;
    
}



img.card-img-top.img-fluid {
    height: 18rem;
} */

.clear-all{
    background-color: rgb(230, 24, 24);
    color: white;
    font-weight: 500;
    width: 150px;
    position: absolute;
    right: 3px;
    top: 35%;
    z-index: 100;
    padding: 10px;
    outline: none;
    border: none;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

/* buttons */

button.position-fixed.clear-all{
        width: 110px !important;
        right: 10px;
}


.product-details-btn{
    cursor: pointer;
    width: 285px;
    margin: 6px;
}
.product-details,
.product-details:hover,
.product-details:focus{
    color: gray;
}


button.btn.btn-warning, 
    button.btn.btn-danger{
        z-index: 101;
    }


@media (max-width:552px) {
     .clear-all{
        width : auto !important;

    }
}


/* end style card */


/* old style for old structure */

/* 
.cart-row
{
   display: flex;
   justify-content: flex-start;
   align-items: flex-start;
   flex-direction: row; 
}

.cart-row .quantity-price-div{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
} */


/* new style for new structure page */


h5{
    display: flex;
    align-items: center;
}


.old-price{
    color:red;
    text-decoration-line: line-through;
    -moz-text-decoration-line: line-through;
    margin: 5px;
    margin-right: 10px;
}



.image-div{
    transition: .3s;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
}


.image-div:hover{
transform: scale(1.1);
-webkit-transform: scale(1.1);
-moz-transform: scale(1.1);
-ms-transform: scale(1.1);
-o-transform: scale(1.1);
opacity: 0.6;
}



/*  card text small screen  */


@media (max-width: 767px) {
    .text-div {
        display: flex;
        flex-direction: column;
        text-align: center;
    }
    h5 {
        align-items: center;
        display: flex;
        justify-content: center;
        padding-top: 13px;
    }

    .del-save {
        display: flex;
        justify-content: center !important;
        align-items: center;
    }

    .incr-decr-icon {
        padding: 20px;
    }

    .incr-icon {
        margin-right: 30px;
    }




    .notAuthAndEmpty-col{
        display: flex;
        justify-content: center;
        flex-direction: column;
    }


}

