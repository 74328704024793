

form.form{    background-color: transparent;
    padding: 46px;
    width:530px;
    margin: auto;
    border-radius: 15px;
    background-color: white;
    color: #009688;
    font-weight: bold;
    border: 1px solid #009688;
    font-size: 18px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}      



.form-control{
    background-color: transparent;
    color:#dc3545;
    height: 50px;
    border: none;
    outline: none;
    border-bottom: 1px solid #009688;
}

.form-control:focus{
    outline: none !important;
    box-shadow: none;
    font-size:large;
    background-color: transparent;
    color: #dc3545;
    
}

.rgister-btn{
    background-color: #009688;
    box-shadow: none;
    outline: none;
    border: none;
}

.rgister-btn:hover,
.rgister-btn:focus{
    background-color: #024842 !important;
}

@media (max-width : 767px) {
    form.form{
        width : auto
    }
}

.error{
    color : red;
    font-size: 12px;
}



.redirectLogin{
    color : #0d6efd ;
    text-decoration-line: underline !important;
}