.product-details-row{
    margin-top: 150px !important;
}

@media (max-width : 767px) {
    .product-details-row{
        display:contents
    } 
}



/* start card body style */

.card-pd{
    width  :auto
}

/* => img */

/* .parent-img-pd{
    width : 400px
}
*/

/* .grand-parent-img-pd{
    display: flex;
    justify-content: center;
    align-items: center;
} */

@media (min-width : 767px) {
    .img-fluid.image-zoom{
        margin-top: 50px;
        width : 300px !important;
        height: 300px !important;
        z-index: 300;
        display: flex;
        justify-content: center;
        align-items: center;
        
    }
}

@media (max-width : 768px) {
    .img-fluid.image-zoom{
        display: flex;
        justify-content: center;
        align-items: center;
        width:230px !important;
        height: 230px !important;
        margin-bottom: 50px;
        /* display: flex;
        justify-content: center;
        align-items: center; */

    }
    .parent-img-pd{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}




/* img <= */


.card-text-pd{
    height: auto;
    text-align: left;
}

@media (max-width : 767px) {
    .text-col-pd{
        width : 320px;
        height: auto;
        text-align: left;
    }
}  


.text-col-pd{
    
    transition:.3s ease-in-out;
    -webkit-transition:.3s ease-in-out;
    -moz-transition:.3s ease-in-out;
    -ms-transition:.3s ease-in-out;
    -o-transition:.3s ease-in-out;
}

.text-col-pd:hover{
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
}
/* end card body */



@media (max-width : 767px) {

    .product-details-row{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}

/* 
@media (max-width : 520px) {
    .container{
        width : 300px
    }
} */

