.footer-section{
    background-color: #212529;
    /* padding-top: 3em; */
    position: relative;
    bottom: 0;
    width: 100%;

}

.text-footer div{
    color: rgba(255, 255, 255, 0.751);
    text-align: center;
    padding-top: 20px;
}