.form-container{
    margin-top: 130px;
}

form.form{
    background-color: #212529;
    padding: 46px;
    width:530px;
    margin: auto;
    border-radius: 15px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}      


.login-btn{
    background-color: #009688;
    box-shadow: none;
    outline: none;
    border: none;
}

.login-btn:hover,
.login-btn:focus{
    background-color: #024842 !important;
}

@media (max-width : 767px) {
    form.form{
        width : auto
    }
}

.error{
    color : red;
    font-size: 12px;
}


.redirectRegister{
    color : #0d6efd ;
    text-decoration-line: underline !important;
}