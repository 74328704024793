.payment-container{
    margin-top: 120px;
}

.checkout-number{
    text-align: center;
   
    padding: 20px;
}

.checkout-number span{
    color : #009688;
}


.payment-row-2 .col{
   
        flex: 1 0;
        padding-right: -246px;
        display: flex;
        justify-content: left;
        align-items: center;
        flex-direction: row;
   
}


@media (max-width : 767px) {
    .payment-row-2 .col{
        display: flex;
        justify-content:center;
        align-items: center;
        flex-direction: column;  
}

}


.image-div{
    margin-right: 15px;
}

@media (max-width:767px) {
    .image-div img{
        width: 200px !important;
        height: 200px !important;
    }
}

@media (min-width:768px) {
    .image-div img{
        width: 350px !important;
        height: 250px !important;
    }
}


.text-div{
    display: flex;
    flex-direction: column;
}


/* start end remove btn  and save for later style  */

/* .remove-basket-btn{
    width : 185px
} */

.delete-all{
    color:gray;
    cursor: pointer;
    width : 150px
}


.del-save{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.del-save h6{
    color:gray;
    cursor: pointer;
    margin: 5px;
}


.del-save h6:hover,
.delete-all:hover{
    text-decoration: underline;
}


/* end remove btn  and save for later style */

.payment-row-3 form{
    padding: 20px;
    position: relative;
}

.payment-row-3 form h3{
    padding: 10px;
    color: #1C4A46;
}

.payment-row-3 form h3 span{
    color:#009688 ;
}

.payment-row-3 form .pay-btn{
    width: 110px;
    position: absolute;
    left:45%;
    margin-top: 20px;
    background-color: #009688;
    border: none;
    cursor: pointer;
    padding:5px;
    color: white;
    font-weight: 700;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.payment-row-3 form .pay-btn:hover{
    background-color: #1C4A46;
}


.payment-error{
    color:red
}

h2{
    text-align: center;
    color: #009688;
    font-size: 23px;
}



